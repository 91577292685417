import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { logout } from '../../store/actions/app'
import AnimatedButton from '../AnimatedButton'
import queryString from 'query-string'
import moment from 'moment'

import { callApi } from '../../utils/api'

import styles from '../../styles/global.module.scss'
import './Header.scss'

import {
  Message,
  Button,
  Menu,
  Icon,
  Popup,
  Image,
  Grid,
  Label,
  Header,
  Segment,
} from 'semantic-ui-react'

const getAvatar = (user) => {
  if (user?.photo && user?.photo.length > 0) {
    return user.photo
  } else {
    const name = user?.entity?.name ?? user?.email
    return `https://ui-avatars.com/api/?name=${name}`
  }
}

const HeaderComponent = (props) => {
  const {
    user,
    rest,
    logout,
    history,
    expirations,
    employees,
    expand,
    setExpand,
    openModal,
    setOpenModal,
    mobileOnly,
    setMobileOnly,
  } = props

  const [orders, setOrders] = useState([])
  const [foodOrders, setFoodOrders] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [notificationsCount, setNotificationsCount] = useState(0)

  const componentDidMount = async () => {
    const params = {
      ['status.eq']: 'RESERVADO',
      ['SysCompany.type_company']: user.company?.type_company,
    }
    let res = await callApi(`/order/utils/get_overdue`, {
      method: 'GET',
      headers: {
        Authorization: user.token,
      },
      params: params,
    })

    setOrders(res.body)
  }

  useEffect(() => {
    componentDidMount()
  }, [])

  useEffect(() => {
    componentDidMount()
  }, [props.orders])

  useEffect(() => {
    let count = orders?.length ?? 0
    setNotificationsCount(count)
  }, [orders])

  let userName = rest.user?.username
  let email = rest.user?.email

  return (
    <Menu
      className="NavBar__Header"
      inverted
      borderless
      size="tiny"
      attached="top"
      fluid
    >
      {!mobileOnly && (
        <Menu.Item className="NavBar__Item">
          <Icon
            className="NavBar__Outline"
            name="list"
            size="large"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setExpand((previousExpandedState) => {
                return previousExpandedState == 2
                  ? 0
                  : previousExpandedState + 1
              })
            }}
          />
        </Menu.Item>
      )}
      <Menu className="SubMenu__Header" size="mini">
        <Menu.Item
          onClick={() => {
            history.push('/')
          }}
        >
          <Header className="NavBar__Item">
            <h4 className="NavBar__Outline">Servicios a Domicilio</h4>
            <Header.Subheader className="NavBar__Outline">
              V 1.0.1
            </Header.Subheader>
          </Header>
        </Menu.Item>
        <Menu.Item position="right">
          <Icon
            className="Circle_Icon NavBar__Item"
            name="question mark"
            circular
            onClick={() => {
              setOpenModal(!openModal)
            }}
          />
        </Menu.Item>
      </Menu>
      
      <Menu.Menu fitted position="right">
        <Menu.Item fitted position="right">
          <Popup
            pinned
            trigger={
              <Menu.Item
                className={styles.shrinkOnHover}
                style={{ marginRight: '1vw', padding: '0 0.8em' }}
              >
                <Icon size="big" name="bell" fitted>
                  {notificationsCount > 0 && (
                    <Label
                      color="red"
                      floating
                      circular
                      style={{
                        fontFamily:
                          "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                        fontSize: '0.45em',
                      }}
                    >
                      {notificationsCount}
                    </Label>
                  )}
                </Icon>
              </Menu.Item>
            }
            content={
              <div>
                {notificationsCount == 0 && (
                  <Message
                    size="tiny"
                    warning
                    icon="info"
                    header={`No hay notificaciones`}
                  />
                )}
                {orders.length > 0 &&
                  user.company.type_company === 'TRANSPORTE' && (
                    <Message
                      size="tiny"
                      onClick={() => {
                        history.push({
                          pathname: '/history_services',
                          search: queryString.stringify({
                            filter: orders.map((e) => e.id).join(','),
                          }),
                        })
                      }}
                      warning
                      icon="warning"
                      header={`${orders.length} Servicio${
                        orders.length > 1 ? 's' : ''
                      } vence${
                        orders.length > 1 ? 's' : ''
                      } en menos de 15 minutos`}
                      content="Click aquí para ver detalles"
                    />
                  )}

                {orders.length > 0 &&
                  user.company.type_company === 'RESTAURANTE' && (
                    <Message
                      size="tiny"
                      onClick={() => {
                        history.push({
                          pathname: '/history_food_services',
                          search: queryString.stringify({
                            filter: orders.map((e) => e.id).join(','),
                          }),
                        })
                      }}
                      warning
                      icon="warning"
                      header={`${orders.length} Orden${
                        orders.length > 1 ? 'es' : ''
                      } vence${
                        orders.length > 1 ? 's' : ''
                      } en menos de 15 minutos`}
                      content="Click aquí para ver detalles"
                    />
                  )}
              </div>
            }
            position="bottom right"
            on="click"
            lastResort="bottom left"
          />
        </Menu.Item>
        <Menu.Item fitted position="right" style={{ marginRight: '2vw' }}>
          <Popup
            onOpen={() => setModalVisible(true)}
            onClose={() => setModalVisible(false)}
            pinned
            on="click"
            trigger={
              <Menu.Item className={styles.shrinkOnHover}>
                <Image src={getAvatar(rest.user)} avatar />
              </Menu.Item>
            }
            content={
              <Grid
                divided
                columns="equal"
                className="Header_PopUp"
                style={{ display: 'inline-block' }}
              >
                <Grid.Row>
                  <Grid.Column textAlign="center" verticalAlign="middle">
                    <Image
                      className="Header_PopUp_Image"
                      src={getAvatar(rest.user)}
                      avatar
                    />
                  </Grid.Column>
                  <Grid.Column textAlign="center" verticalAlign="middle">
                    <div className="Header_PopUp_Name" style={{}}>
                      {email}
                    </div>
                    <div className="Header_PopUp_Email">
                      {rest.user?.role.name}
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      className="Header_PopUp_Button"
                      basic
                      icon
                      labelPosition="left"
                      onClick={() => {
                        logout()
                      }}
                    >
                      <Icon name="sign out" />
                      Cerrar sesión
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            }
            position="bottom right"
          />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
})

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.app.user,
  user: state.app.user,
  employees: state.employees,
  orders: state.orders,
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
