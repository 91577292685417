import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const swal = withReactContent(Swal)

export const showErrorMessage = (res) => {
	let message = res ? (res.error ? res.error : JSON.stringify(res.error)) : {}

	message =
		typeof message != 'string'
			? 'Ha ocurrido un error. Favor de contactar el administrador del sistema.'
			: message

	swal
		.fire({
			title: '¡Advertencia!',
			text: message,
			icon: 'error',
			showCancelButton: true,
			cancelButtonText: 'Cerrar',
		})
		.then((result) => {
			if (result.dismiss === swal.DismissReason.cancel) {
				swal.close()
			} else if (result.dismiss === swal.DismissReason.close) {
				swal.close()
			} else if (result.dismiss === swal.DismissReason.backdrop) {
				swal.close()
			}
		})
}

export const showSuccessMessage = (res) => {
	const message =
		typeof res != 'string'
			? 'Ha ocurrido un error. Favor de contactar el administrador del sistema.'
			: res

	swal
		.fire({
			title: '¡Acción Realizada!',
			text: message,
			icon: 'success',
			showCancelButton: true,
			cancelButtonText: 'Cerrar',
		})
		.then((result) => {
			if (result.dismiss === swal.DismissReason.cancel) {
				swal.close()
			} else if (result.dismiss === swal.DismissReason.close) {
				swal.close()
			} else if (result.dismiss === swal.DismissReason.backdrop) {
				swal.close()
			}
		})
}
