import React from 'react'
import AppRouter from "./routes/index";

import { Provider } from 'react-redux';
import configureStore from './store/';
import { ToastContainer } from 'react-toastify';

import './styles/index.css';
import './styles/index.scss';
import './styles/menu.css'
import './styles/menu.scss'
import 'react-toastify/dist/ReactToastify.css';

const store = configureStore();

function App() {

  return (

    <Provider store={store}>
      <div className="">
        <AppRouter />
        <ToastContainer />
      </div>
    </Provider>
  );
}

export default App;
