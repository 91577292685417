const initialState = {
}
export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'LOGIN': {
      return {
        ...state,
        user: { 
          ...state.user,
          ...payload 
        }
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        user: null,
      };
    }

    default:
      return state;
  }
};
