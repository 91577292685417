import crudHandlers from "./_crud";

export default (state, action) => {
  const {type} = action;
  switch (type) {
    
    case "LOAD_REPORT_DOCUMENT_ITEM": {
      var { response, payload } = action;
      return {
        ...state,
        is_fetching: false,
        expiration_report: response
      }
    }

    default:

      state = crudHandlers(state, action, 'document_item')

      return state
  }
}