import { useState, useEffect, Fragment } from 'react'
import ReactDOM from 'react-dom'
import { Route, Redirect } from 'react-router-dom'

import { Header, Segment, Grid, Modal, Divider, List } from 'semantic-ui-react'
import { connect } from 'react-redux'
import SidebarMenu from 'components/Sidebar/SidebarMenu'
import MenuHeader from 'components/Header/Header'

const PrivateRoute = (
	{ isAuthenticated = true, component: Component, ...rest },
	props
) => {
	const [expand, setExpand] = useState(0)
	const [openModal, setOpenModal] = useState(false)
	const [mobileOnly, setMobileOnly] = useState(false)
   const [previousExpand, setPreviousExpand] = useState(null)
	return (
		<>
			<Route
				{...rest}
				render={(props) =>
					isAuthenticated ? (
						<Fragment>
							<MenuHeader
								rest={rest}
								logout={rest.logout}
								history={props.history}
								expand={expand}
								setExpand={setExpand}
                        previousExpand={previousExpand}
                        setPreviousExpand={setPreviousExpand}
								openModal={openModal}
								setOpenModal={setOpenModal}
								mobileOnly={mobileOnly}
								setMobileOnly={setMobileOnly}
							/>
							<div
								className="main-content"
								style={
									(expand == null|| expand == 2)
										? { marginLeft: '250px' }
										: expand == 1
										? { marginLeft: '55px' }
										: null
								}
							>
								{ReactDOM.createPortal(
									<SidebarMenu
										history={props.history}
                              location={props.location}
										user={rest.user}
										logout={rest.logout}
										rest={rest}
										expand={expand}
										setExpand={setExpand}
                              previousExpand={previousExpand}
                              setPreviousExpand={setPreviousExpand}
										openModal={openModal}
										setOpenModal={setOpenModal}
										mobileOnly={mobileOnly}
										setMobileOnly={setMobileOnly}
									/>,
									document.getElementById('root')
								)}

								<Component {...props} />
							</div>
						</Fragment>
					) : (
						<Redirect to="/login" />
					)
				}
			/>
			<Modal
				closeIcon
				basic
				centered={true}
				onClose={() => setOpenModal(false)}
				onOpen={() => setOpenModal(true)}
				open={openModal}
				size="large"
			>
				<Modal.Content content>
					<Segment inverted style={{ height: '100%' }}>
						<Grid stackable divided inverted>
							<Grid.Row columns={2}>
								<Grid.Column width={6} verticalAlign="middle" textAlign="center">
									<Header inverted content="© Geologistic GPS 2022" />
								</Grid.Column>
								<Grid.Column width={8}>
									<Header inverted content="Contacto" />
									<Divider></Divider>
									<Header
										size="small"
										inverted
										content="¿Necesitas registrar un nuevo ticket o solicitud?"
									/>
									<List divided inverted>
										<List.Item
											header="Ingresa al Portal: "
											content="https://geologistic.com.mx/Soporte/"
											style={{ cursor: 'pointer' }}
											onClick={() => {
												window.open('https://geologistic.com.mx/Soporte/', '_blank')
											}}
										/>
										<List.Item
											header="Soporte"
											content="(686) 214 5447, (686) 385 6938,  (686) 510 9915"
										/>
										<List.Item header="Oficina" content="(686) 372 9877" />
										<List.Item
											header="Emergencias"
											content="(686) 420 3333, (686) 214 5447, (686) 385 6938, (686) 510 9915,  (686) 123 6454"
										/>
										<List.Item header="Administración" content="(686) 122 0316" />
										<List.Item
											header="Ventas"
											content="(686) 510 9915, (686) 196 4736, (686) 420 8586,  (686) 191 1571"
										/>
									</List>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</Modal.Content>
			</Modal>
		</>
	)
}

const mapDispatchToProps = (dispatch) => ({})

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.app.user,
	user: state.app.user,
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
