import { callApi } from '../../utils/api'

export const createNewAccount = (values) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/create_new_account`, {
				method: 'POST',
				body: JSON.stringify(values),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@token', token)

					dispatch({
						type: 'LOGIN',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const verifyEmail = (values) => {
	return async (dispatch, getState) => {
		const state = getState()
		try {
			const res = await callApi(`/app/verify_email`, {
				method: 'POST',
				body: JSON.stringify(values),
				headers: {
					Authorization: state.app.user.token,
				},
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@token', token)

					dispatch({
						type: 'LOGIN',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const login = ({ email, password }) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login`, {
				method: 'POST',
				body: JSON.stringify({
					email,
					password,
				}),
			})

			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@token', token)

					dispatch({
						type: 'LOGIN',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const loginWithToken = (options) => {
	return async (dispatch, getState) => {
		try {
			const res = await callApi(`/app/login/token`, {
				method: 'POST',
				body: JSON.stringify({
					token: options.token,
				}),
			})
			if (res.status === 200) {
				try {
					const token = res.body.token
					localStorage.setItem('@token', token)
					dispatch({
						type: 'LOGIN',
						payload: res.body,
					})
				} catch (e) {
					// saving error
				}
			} else {
			}
		} catch (error) {
			console.log(error.message.error)
		}
	}
}

export const logout = () => {
	try {
		localStorage.removeItem('@token')
		localStorage.removeItem('@employee_code')
	} catch (e) {
		// remove error
	}
	return {
		type: 'LOGOUT',
	}
}

export const updatePassword = (item) => {
	return async (dispatch, getState) => {
		try {
			const state = getState()
			const res = await callApi(`/user/change_password`, {
				method: 'POST',
				body: JSON.stringify(item),
				headers: {
					Authorization: state.app?.user?.token,
				},
				successMessage: 'Se actualizó correctamente',
			})

			dispatch({
				type: 'UPDATE_PASSWORD',
				payload: res.body,
			})
		} catch (error) {
			console.log(error.message.error)
		}
	}
}
