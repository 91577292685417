import { createStore, combineReducers, applyMiddleware, compose } from 'redux'

//  Reducers
import appReducer from './reducers/app'
import companyReducer from './reducers/companys'
import userReducer from './reducers/users'
import roleReducer from './reducers/roles'
import clientsReducer from './reducers/clients'
import addressesReducer from './reducers/addresss'
import serviceReducer from './reducers/services'
import driverReducer from './reducers/drivers'
import orderReducer from './reducers/orders'
import sysCompanyReducer from './reducers/sys_companys'
import sysModuleReducer from './reducers/sys_modules'
import foodExtraReducer from './reducers/food_extras'
import documentItemsReducer from './reducers/document_items'
import foodExtraCategoryReducer from './reducers/food_extras_categorys'

//Middleware
import thunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
	const store = createStore(
		combineReducers({
			app: appReducer,
			companys: companyReducer,
			users: userReducer,
			roles: roleReducer,
			clients: clientsReducer,
			addresss: addressesReducer,
			services: serviceReducer,
			drivers: driverReducer,
			orders: orderReducer,
			sys_companys: sysCompanyReducer,
			sys_modules: sysModuleReducer,
			food_extras: foodExtraReducer,
			document_items: documentItemsReducer,
			food_extra_categorys: foodExtraCategoryReducer,
		}),
		composeEnhancers(applyMiddleware(thunk))
	)
	return store
}
