import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Icon, Button, Header, Input, Popup, Confirm } from 'semantic-ui-react'

import styles from '../styles/global.module.scss'

const AnimatedButton = (props) => {
	const {
		size,
		float,
		iconname,
		disabled,
		placeholder = undefined,
		animated = true,
		rotate,
		title,
		content,
		changedValues = false,
		holdPopup = false,
		holdPopupContent,
		bordered = false,
		basic = false,
		style,
		popupSize,
		willConfirm = false,
		confirmMessage,
		confirmTitle,
		cancelMessage,
		onConfirm,
      isLoading=false
	} = props

	const [clicked, setClicked] = useState(false)
	const [open, setOpen] = useState(false)

	const onClick = (e) => {
		e.preventDefault()
		setClicked(!clicked)
		props.onClick && props?.onClick(e)
		onConfirm && onConfirm()
		setOpen(false)
	}
	return (
		<>
			{placeholder && !holdPopup && !willConfirm && (
				<Popup
					trigger={
						<button
							className={
								`${size} ui${basic ? ' ' : ' right labeled '}icon button ${
									bordered ? styles.Bordered : ' '
								}` +
								' ' +
								styles.IconButton +
								`${animated ? ' ' + styles['pulse-grow-on-hover'] : ' '}`
							}
							disabled={disabled}
							onClick={onClick}
							style={style}
							{...props}
						>
							<Icon
								className={styles.IconLabel}
								name={iconname}
								rotated={rotate}
							></Icon>
							{content}
						</button>
					}
					content={placeholder}
					on="hover"
				/>
			)}
			{!placeholder && !holdPopup && !willConfirm && (
				<button
					className={
						` ${size} ui${basic ? ' ' : ' right labeled '}icon button ${
							bordered ? styles.Bordered : ' '
						}` +
						' ' +
						styles.IconButton +
						`${animated ? ' ' + styles['pulse-grow-on-hover'] : ' '}`
					}
					disabled={disabled}
					onClick={onClick}
					style={style}
					type="button"
					{...props}
				>
					{content ? content : title}
					<Icon className={styles.IconLabel} name={iconname} rotated={rotate}>
						{' '}
					</Icon>
				</button>
			)}
			{!placeholder && holdPopup && !willConfirm && (
				<Popup
					trigger={
						<button
							size={size}
							className={
								` ${size} ui${basic ? ' ' : ' right labeled '}icon button ${
									bordered ? styles.Bordered : ' '
								}` +
								' ' +
								styles.IconButton +
								`${animated ? ' ' + styles['pulse-grow-on-hover'] : ' '}`
							}
							floated={float}
							disabled={disabled}
							onClick={onClick}
							{...props}
						>
							<Icon name={iconname} rotated={rotate}></Icon>
						</button>
					}
					content={holdPopupContent}
					on="click"
					position="right center"
					onClose={props.onClose}
					size={size}
					style={popupSize}
				/>
			)}
			{!placeholder && !holdPopup && willConfirm && (
				<>
					<button
						className={
							` ${size} ui${basic ? ' ' : ' right labeled '}icon button ${
								bordered ? styles.Bordered : ' '
							}` +
							' ' +
							styles.IconButton +
							`${animated ? ' ' + styles['pulse-grow-on-hover'] : ' '}`
						}
						disabled={disabled}
						onClick={(e) => {
							e.preventDefault()
							setOpen(true)
						}}
						style={style}
						type="button"
						{...props}
					>
						{content ? content : title}
						<Icon className={styles.IconLabel} name={iconname} rotated={rotate}>
							{' '}
						</Icon>
					</button>
					<Confirm
						open={open}
						onCancel={(e) => {
							e.preventDefault()
							setOpen(false)
						}}
						onConfirm={onClick}
						content={confirmTitle}
						cancelButton={cancelMessage}
						confirmButton={confirmMessage}
					/>
				</>
			)}

			{placeholder && !holdPopup && willConfirm && (
				<>
					<Popup
						content={placeholder}
						trigger={
							<button
								className={
									` ${size} ui${basic ? ' ' : ' right labeled '}icon button ${
										bordered ? styles.Bordered : ' '
									}` +
									' ' +
									styles.IconButton +
									`${animated ? ' ' + styles['pulse-grow-on-hover'] : ' '}`
								}
								disabled={disabled}
								onClick={(e) => {
									e.preventDefault()
									if (changedValues) setOpen(true)
									else onClick(e)
								}}
								style={style}
								type="button"
								{...props}
							>
								{content ? content : title}
								<Icon className={styles.IconLabel} name={iconname} rotated={rotate}>
									{' '}
								</Icon>
							</button>
						}
					/>

					<Confirm
						open={open}
						onCancel={(e) => {
							e.preventDefault()
							setOpen(false)
						}}
						onConfirm={onClick}
						content={confirmTitle}
						cancelButton={cancelMessage}
						confirmButton={confirmMessage}
					/>
				</>
			)}
		</>
	)
}

AnimatedButton.propTypes = {
	size: PropTypes.string,
	float: PropTypes.string,
	iconname: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	animated: PropTypes.bool,
	holdPopup: PropTypes.bool,
	holdPopupContent: PropTypes.object,
	onClose: PropTypes.func,
	bordered: PropTypes.bool,
}

export default AnimatedButton
