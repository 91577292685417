import Decimal from 'decimal.js'
import moment from 'moment'
import 'moment/locale/es'
import _ from 'lodash'
moment.locale('es')

const days = [
	{ key: 1, label: 'Lunes' },
	{ key: 2, label: 'Martes' },
	{ key: 3, label: 'Miércoles' },
	{ key: 4, label: 'Jueves' },
	{ key: 5, label: 'Viernes' },
	{ key: 6, label: 'Sábado' },
	{ key: 0, label: 'Domingo' },
]

export const formatColumn = (format, value, datecustom) => {
	const type = typeof format === 'string' ? format : format.type
	const decimals = typeof format === 'string' ? 2 : format.decimals

	const transform = function (org, n, x, s, c) {
		const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
			num = org.toFixed(Math.max(0, ~~n))

		return (c ? num.replace('.', c) : num).replace(
			new RegExp(re, 'g'),
			'$&' + (s || ',')
		)
	}
	switch (type) {
		case 'currency':
			return !isNaN(value)
				? `$${transform(parseFloat(value), decimals, 3, ',', '.')}`
				: value
		case 'number':
			return !isNaN(value)
				? `${transform(parseFloat(value), decimals, 3, ',', '.')}`
				: value
		case 'percentage':
			return !isNaN(value)
				? `${transform(parseFloat(value), decimals, 3, ',', '.')}%`
				: value
		case 'date':
			if (!value) {
				return ''
			}
			return moment(value).format('DD-MM-YYYY')
		case 'datecustom':
			if (!value) {
				return ''
			}
			return moment(value).format(datecustom)
		default:
			return value
	}
}

export const convertToArrayObject = (array) => {
	return array.reduce((current, item) => {
		const id = item._id || item.id
		current[id] = item
		return current
	}, {})
}

export const getDayByNumber = (day) => {
	return days.find((d) => d.key == day)
}

export const insertAt = (array, index, ...elements) => {
	array.splice(index, 0, ...elements)
}

export const replaceAll = (text = '', search, replacement) => {
	if (text == null || typeof text !== 'string') return ''
	text = text.replace(new RegExp(search, 'g'), replacement)

	return text
}

export const getObjectProp = function (object, stringProp) {
	let o = object
	let a = getArrayFromNestedProp(stringProp)
	for (var i = 0, n = a.length; i < n; ++i) {
		var k = a[i]
		if (o == null) return
		if (k in o) {
			o = o[k]
		} else {
			return
		}
	}
	return o
}

export const getArrayFromNestedProp = function (stringProp) {
	let s = stringProp
	s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
	s = s.replace(/^\./, '') // strip a leading dot
	let a = s.split('.')
	return a
}

/**
 * Converts an object to an array. If filterFunc is provided it will use it to filter the result array
 * @param {Object} obj - The object that will be convert to an array.
 * @param {Function} [filterFunc] - The function to filter the result array.
 * @return {Array} - The result array.
 * @example
 * convertObjectToArray({
 *    '0': {
 *    'name': 'Julio'
 *    },
 *    '1': {
 *    'name': 'Andre'
 *    }
 * })
 * @example
 * convertObjectToArray({
 *    '0': {
 *    'name': 'Julio'
 *    },
 *    '1': {
 *    'name': 'Andre'
 *    }
 * }, (item) => item.name === 'Julio' )
 */
export const convertObjectToArray = (obj, filterFunc) => {
	if (obj === null || obj === undefined) return []
	if (filterFunc) {
		return Object.keys(obj)
			.map((key) => obj[key])
			.filter(filterFunc)
	} else {
		return Object.keys(obj).map((key) => obj[key])
	}
}

export const bytesToSize = (bytes) => {
	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
	if (bytes === 0) return '0 Byte'
	var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

export const sortByDate = (a, b) => {
	if (moment(a.date).isAfter(b.date)) {
		return -1
	} else {
		return 1
	}
}

export const simpleSum = (a, b) => {
	const A = new Decimal(a)
	const B = new Decimal(b)

	return A.plus(B)
}

export const simpleSub = (a, b) => {
	const A = new Decimal(a)
	const B = new Decimal(b)

	return A.minus(B)
}

/**
 * Returns a unique copy of the given object array by the specified property, ignoring
 * any other property in each object.
 * @param {Array <Object> } array - The original array.
 * @param {String} prop - The object prop that specifies the uniqueness parameter
 *
 * @returns {Array <Object>} The result unique array.
 *
 * @example
 * let array = [{ foo: 'one' bar: 3}, { foo: 'one' bar: 2}, { foo: 'three' bar: 1}]
 *
 * let uniq_array = getUniqArray(array, 'foo')
 * // [{ foo: 'one' bar: 3}, { foo: 'three' bar: 1}]
 */
export const getUniqArray = (array, prop) => {
	const newProp = prop.replaceAll('.', '_').toLowerCase()
	let copy = array.map((member) => {
		let value = getObjectProp(member, prop)
		if (value) {
			return { ...member, [newProp]: value }
		}
		return member
	})
	return _.uniqBy(copy, newProp)
}

/**
 * Returns an sorted copy of the given object array by a specific prop, specifying the sort order
 * @param {Array <Object> } array - The original array.
 * @param {Object} sorter - The sorter object that specifies the sort order
 * @param {('asc'|'desc')} sorter.order - One of 'asc' and 'desc'
 * @param {String} sorter.accessor - The object prop to order by
 *
 * @returns {Array <Object>} The sorted array.
 *
 * @example
 * let array = [{ foo: 'one' bar: 3}, { foo: 'two' bar: 2}, { foo: 'three' bar: 1}]
 *
 * let sorted_array = getSortedArray(array, {accessor: 'foo', order: 'asc'})
 * // [{ foo: 'one' bar: 3}, { foo: 'three' bar: 1}, { foo: 'two' bar: 2}]
 */
export const getSortedArray = (array, sorter) => {
	let sortedArray = _.orderBy(
		array,
		[sorter?.accessor ?? null],
		[(sorter?.order ?? null)?.toLocaleLowerCase() ?? null]
	)
	return sortedArray
}

/** Calculate the total of an array of items using an especific property
 * @param {Array<Object>} flatListItems The flat list of items that would be groupped by the property
 * @param {String} property The property that will be used to group items
 * @return {Object} Key-value pairs with items groupped where key will be the property value
 */
export const calculateSimpleTotal = (flatListItems, property) => {
	return flatListItems.length > 0
		? flatListItems.reduce((acum, item) => {
				const value = item[property] ?? 0
				return acum.plus(value)
		  }, new Decimal(0))
		: new Decimal(0)
}

/**
 *
 * Deep compares two objects, it will identify attributes that are object to check difference between those values
 *
 * @param {Object} current - The current object which holds new attributes values
 * @param {Object} original - The original object which holds the original attributes values
 * @returns An object created from the difference between the original and the current objects.
 *
 */

export const difference = (current, original, exclude = []) => {
	function changes(current, original) {
		return _.transform(current, function (result, value, key) {
			// accumulates an object, works like reduce
			const excluded = exclude.includes(key)
			//find if key it's on the exclusion array
			if (!_.isEqual(value, original[key]) && !excluded) {
				//if original value differs from current value and key its not excluded
				//checks whether the value is an Object, if it is, it will call the function recursively
				//if not, it will accumulate the value
				result[key] =
					_.isObject(value) && _.isObject(original[key])
						? changes(value, original[key])
						: value
			} else if (excluded) {
				result[key] = value
			}
		})
	}
	return changes(current, original) //returns the changes between current and original
}
